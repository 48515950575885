import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HeroEvent = _resolveComponent("HeroEvent")!
  const _component_TeamTitle = _resolveComponent("TeamTitle")!
  const _component_Subtitle = _resolveComponent("Subtitle")!
  const _component_TeamStat = _resolveComponent("TeamStat")!

  return (_ctx.event.id !== undefined)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_HeroEvent, {
          team: _ctx.event.team,
          ev: _ctx.event,
          config: _ctx.config,
          backgroundPhoto: _ctx.event.backgroundPhoto
        }, null, 8, ["team", "ev", "config", "backgroundPhoto"]),
        _createVNode(_component_TeamTitle, {
          team: _ctx.event.team,
          config: _ctx.config
        }, null, 8, ["team", "config"]),
        _createVNode(_component_Subtitle, {
          content: "Social media stats",
          config: _ctx.config
        }, null, 8, ["config"]),
        _createVNode(_component_TeamStat, {
          ev: _ctx.event,
          team: _ctx.event.team,
          config: _ctx.config
        }, null, 8, ["ev", "team", "config"])
      ]))
    : _createCommentVNode("", true)
}