
import {Options, Vue} from "vue-class-component";
import {Helper} from "@/services/Helper";

@Options({
  components: {

  },
  props: {
    team: {
      required: true
    },
    ev: {
      required: true
    },
    config: {
      required: true
    }
  },

})
export default class TeamStat extends Vue {
  formatNumber(value:number): string {
    return Helper.numberFormat(value);
  }

  getBarStyle(config: any) {
    let obj = {} as any;
    if (config === undefined) {
      config = {};
    }

    if (config.mainColor !== undefined && config.mainColor !== null) {
      obj['backgroundColor'] = config.mainColor;
    }

    return obj;
  }

  getTextColor(config: any) {
    let obj = {} as any;
    if (config === undefined) {
      config = {};
    }

    if (config.textTabColor !== undefined && config.textTabColor !== null) {
      obj['color'] = config.textTabColor;
    }

    return obj;
  }
}
