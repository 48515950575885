

import Settings from "@/services/Settings";
import {Options, Vue} from "vue-class-component";
import Subtitle from "@/components/elements/Subtitle.vue";
import Header from "@/components/elements/Header.vue";
import HeroEvent from "@/components/elements/HeroEvent.vue";
import TeamTitle from "@/components/title/TeamTitle.vue";
import TeamStat from "@/components/elements/TeamStat.vue";
@Options({
  components: {
    Subtitle,
    Header,
    HeroEvent,
    TeamTitle,
    TeamStat
  },
})
export default class Event extends Vue {
  private id: string | null = null;
  private host: string | null = null;
  private loader = false;
  private loaderFeedData = false;
  private event: any = {};
  private projectId: string | undefined;
  private config = {};

  loadData(): void {
    if (this.loader) return;
    this.loader = true;
    this.$http.get(this.host + '/public/events/' + this.id, {
      params: {}
    }).then((response) => {
      this.event = response.data;
      this.projectId = response.data.projectTeamId;
      this.config = response.data.config;
      this.loader = false;
    }, () => {
      this.loader = false;
    });
  }

  mounted(): void {
    this.id = this.$route.params.eventId as string;
    const settings = new Settings();
    this.host = settings.getSetting('apiHost');
    this.loadData();
  }
}

